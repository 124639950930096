import { IRowHookOutputInternal } from 'dromo-uploader-js'
import { IRowToAdd, IRowHookInput } from 'dromo-uploader-js/dist/interfaces'
import {
  ProposedSite,
  ProposedSiteAction,
  SiteAction,
  SiteListType,
} from '@black-bear-energy/black-bear-energy-common'
import {
  uploadMatchedRecordSiteActionOptions,
  uploadUnmatchedRecordSiteActionOptions,
  existingRecordSiteActionOptions,
} from './FieldSchema'
import { AxiosInstance } from 'axios'

export async function generateSiteActions({
  records,
  isUpdate,
  siteListType,
  clientId,
  api,
}: {
  records: IRowHookInput[]
  isUpdate: boolean
  siteListType: SiteListType
  clientId: number
  api: AxiosInstance
}): Promise<{
  updatedRows: IRowHookOutputInternal[]
  newRows: IRowToAdd[]
  siteActionResponse: ProposedSiteAction<{ [key: string]: string }>[]
}> {
  if (records.length === 0) {
    return { updatedRows: [], newRows: [], siteActionResponse: [] }
  }

  const proposedSites: ProposedSite[] = records.map((record) => ({
    id: record.rowId,
    clientDesignation: record.row.clientDesignation?.value as
      | string
      | undefined,
    name: record.row.name.value as string,
    latitude: record.row.latitude.value
      ? parseFloat(String(record.row.latitude.value))
      : undefined,
    longitude: record.row.longitude.value
      ? parseFloat(String(record.row.longitude.value))
      : undefined,
  }))

  const siteActionResponse = (
    await api.post<ProposedSiteAction<{ [key: string]: string }>[]>(
      '/sites/generate-actions',
      {
        sites: proposedSites,
        clientId,
        siteListType,
      }
    )
  ).data

  const rowFields = Object.keys(records[0].row)

  const updatedRows: IRowHookOutputInternal[] = []
  const newRows: IRowToAdd[] = []

  for (const siteAction of siteActionResponse) {
    if (
      siteListType !== SiteListType.Sold &&
      siteAction.action === SiteAction.Sold
    ) {
      // create a new row and populate with the existing db record's data
      newRows.push(generateSoldRecordRow(siteAction, rowFields))
      continue
    }
    // apply to the existing row
    updatedRows.push(
      setSiteAction(
        siteListType,
        siteAction,
        records.find((r) => r.rowId === siteAction.id) as IRowHookInput,
        isUpdate
      )
    )
  }
  return {
    updatedRows,
    newRows,
    siteActionResponse,
  }
}

function generateSoldRecordRow(
  siteAction: ProposedSiteAction<{ [key: string]: string }>,
  rowFieldNames: string[]
): IRowToAdd {
  if (siteAction.action !== SiteAction.Sold) {
    throw new Error('generateSoldRecordRow() called for invalid site action')
  }
  const row = rowFieldNames.reduce(
    (row, currField) => ({
      ...row,
      [currField]: { value: siteAction.record[currField] },
    }),
    {} as IRowToAdd['row']
  )
  row.siteAction = {
    value: SiteAction.Review,
    info: [{ message: 'Potentially sold', level: 'info' }],
    selectOptions: existingRecordSiteActionOptions,
  }
  // set the id of the existing record
  row.existingRecordId.value = siteAction.record.id
  // mark this as a potentially sold record added from the db
  row.maybeSold.value = true
  return { row }
}

function setSiteAction(
  siteListType: SiteListType,
  siteAction: ProposedSiteAction<{ [key: string]: string }>,
  record: IRowHookOutputInternal,
  isUpdate: boolean
): IRowHookOutputInternal {
  const recordCopy = { ...record }
  const rowSiteActionCopy = { ...recordCopy.row.siteAction }
  // remove any warnings we may have previously added
  rowSiteActionCopy.info = []

  switch (siteAction.action) {
    case SiteAction.Update:
      rowSiteActionCopy.selectOptions = uploadMatchedRecordSiteActionOptions
      recordCopy.row.existingRecordId.value = siteAction.existingRecordId
      break
    case SiteAction.Review:
      const message = getSiteReviewMessage(siteAction, record, isUpdate)
      if (message) {
        rowSiteActionCopy.info = [{ message, level: 'info' }]
      }
      const potentialMatchExists = 'matchId' in siteAction
      if (potentialMatchExists) {
        rowSiteActionCopy.selectOptions = uploadMatchedRecordSiteActionOptions
        recordCopy.row.existingRecordId.value = siteAction.matchId
      } else {
        rowSiteActionCopy.selectOptions = uploadUnmatchedRecordSiteActionOptions
      }
      break
    case SiteAction.Sold:
      if (siteListType !== SiteListType.Sold) {
        throw new Error(
          'User must review sold sites if import type is not sold sites only'
        )
      }
      rowSiteActionCopy.selectOptions = existingRecordSiteActionOptions
      recordCopy.row.existingRecordId.value = siteAction.record.id
      break
    case SiteAction.New:
      rowSiteActionCopy.selectOptions = uploadUnmatchedRecordSiteActionOptions
      break
    case SiteAction.Ignore:
      throw new Error('Ignore should never be a suggested action')
    default:
      throw new Error(`Unrecognized site action: ${JSON.stringify(siteAction)}`)
  }
  rowSiteActionCopy.value = siteAction.action
  recordCopy.row.siteAction = rowSiteActionCopy
  return recordCopy
}

function getSiteReviewMessage(
  siteAction: ProposedSiteAction,
  record: IRowHookOutputInternal,
  isUpdate: boolean
): string | null {
  if (siteAction.action !== SiteAction.Review) {
    return null
  }

  if (siteAction.isUnmatchedSoldSite) {
    return 'No existing record found that matches this site'
  }

  if ('matchAddress' in siteAction) {
    const clientDes = siteAction.matchClientDesignation
      ? `<br>Client Designation: "${siteAction.matchClientDesignation}"`
      : ''
    const potentialMatchDetails = `Name: "${siteAction.matchName}"${clientDes}<br>Address: ${siteAction.matchAddress}`
    if (!record.row.isGeocodeValid.value && !isUpdate) {
      return `Address could not be geocoded, but location fields were copied from this potential match:<br><br>${potentialMatchDetails}`
    } else {
      return `Potential match found:<br><br>${potentialMatchDetails}`
    }
  }

  const missingInfo: string[] = []
  if ('needsName' in siteAction) {
    if (siteAction.needsName) {
      missingInfo.push('<br>Name')
    }
    if (siteAction.needsLat) {
      missingInfo.push('<br>Latitude')
    }
    if (siteAction.needsLong) {
      missingInfo.push('<br>Longitude')
    }
  }
  if (missingInfo.length === 0) {
    return null
  }

  return `Required info missing:${missingInfo.join('')}`
}
